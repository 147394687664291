<template>
  <section>
    <b-modal id="create-room" v-model="showCreateRoomModal" :width="540" scroll="keep" :on-cancel="handleClose">
      <h4 class="brand">PROFIT</h4>
      <h2 class="modal-title medium">{{ title }}</h2>
      <b-steps
          v-model="activeStep"
          :animated="isAnimated"
          :rounded="isRounded"
          :has-navigation="hasNavigation"
          :icon-prev="prevIcon"
          :icon-next="nextIcon"
          :label-position="labelPosition"
          :mobile-mode="mobileMode">
        <b-step-item step="0" label="Room Details" :clickable="isStepsClickable">
          <ValidationObserver tag="div" class="form-observer" ref="roomFormValidator" v-slot="{ invalid }">
            <form @submit.prevent="handleRoomSubmit">
              <ValidationProvider tag="div" class="validation-field" name="Room Name" rules="required|min:4"
                                  v-slot="{ errors, valid }">
                <b-field label="Room Name"
                         class="form-field"
                         :class="{ 'is-danger': errors[0], 'is-success': valid }"
                         :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <b-input class="signup-input" vid="room_name" v-model="room_name" :readonly="loading"></b-input>
                </b-field>
                <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
              </ValidationProvider>

              <ValidationProvider tag="div" class="validation-field" name="Server Name" rules="required"
                                  v-slot="{ errors, valid }">
                <b-field label="Server Name"
                         class="form-field"
                         :class="{ 'is-danger': errors[0], 'is-success': valid }"
                         :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <b-input class="signup-input" vid="server_name" v-model="server.name" :readonly="true"></b-input>
                </b-field>
                <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
              </ValidationProvider>

              <ValidationProvider tag="div" class="validation-field" name="Room Description" v-slot="{ errors, valid }">
                <b-field label="Description"
                         class="form-field"
                         :class="{ 'is-danger': errors[0], 'is-success': valid }"
                         :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <b-input class="signup-input" vid="room_desc" v-model="room_desc"></b-input>
                </b-field>
                <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
              </ValidationProvider>

              <footer class="footer">
                <b-button
                    class="submit signup-submit"
                    type="submit"
                    :loading="loading"
                    :disabled="invalid || loading"
                    @click="activeStep = 1"
                >Let's Go
                </b-button>
              </footer>
            </form>
          </ValidationObserver>
        </b-step-item>

        <b-step-item step="1" label="Room Details" :clickable="isStepsClickable">
          <b-field label=""
                   class="form-field has-toggle">
            <b-switch id="free-switch" class="switch-basic" v-model="isFree">Free Server</b-switch>
          </b-field>

          <ValidationObserver tag="div" class="form-observer" ref="editServerFormValidator" v-slot="{ invalid }">
            <form id="edit-server-form" @submit.prevent="handleRoomSubmit">
              <div v-show="!isFree">
                <ValidationProvider tag="div" class="validation-field" name="Billing Cycle" rules="required"
                                    v-slot="{ errors, valid }">
                  <b-field
                      label="Billing Cycle"
                      class="form-field">
                    <b-dropdown v-model="selectedBillingCycle"
                                aria-role="list"
                                class="categories-select2"
                    >
                      <button class="button" id="buttonCycle" type="button" slot="trigger" :expanded="true">
                        <template>
                          <span style="color: rgba(255, 255, 255, 0.84)">{{ selectedBillingCycle }}</span>
                        </template>
                        <b-icon style="color: rgba(255, 255, 255, 0.84)" icon="menu-down"></b-icon>
                      </button>
                      <b-dropdown-item v-for="(billingCycle, i) in billingCycleOptions" :key="i" :value="billingCycle"
                                       aria-role="listitem"
                      >{{ billingCycle }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-field>
                  <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
                </ValidationProvider>

                <ValidationProvider
                    tag="div"
                    class="validation-field"
                    name="Room Price"
                    rules="required|min_value:0|double:2"
                    v-slot="{ errors, valid }"
                >
                  <b-field
                      label="Price"
                      class="form-field"
                      :class="{ 'is-danger': errors[0], 'is-success': valid }"
                      :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  >
                    <b-input type="text" class="signup-input" vid="room_price" v-model="room_price"/>
                  </b-field>
                  <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
                </ValidationProvider>
              </div>

              <footer class="footer">
                <b-button
                    class="submit signup-submit"
                    type="submit"
                    :loading="loading"
                    :disabled="invalid"
                    @click="handleRoomSubmit()"
                >Create
                </b-button>
              </footer>
            </form>
          </ValidationObserver>

        </b-step-item>

        <b-step-item step="2" label="Created" :clickable="isStepsClickable">
          <h1 style="text-align: center;font-size: 120%;">Your Room has been Created!</h1>
          <sweetalert-icon icon="success"></sweetalert-icon>
          <!--h1 style="text-align: center;font-size: 120%;">Go to Room</h1-->
          <footer id="account-confirm-footer" class="footer">
            <!--b-button
                class="submit"
                type="submit"
                @click="goToRoom"
            >Go to Room</b-button-->
            <b-button
                class="submit"
                type="submit"
                @click="handleClose"
            >Close
            </b-button>
          </footer>
        </b-step-item>
      </b-steps>
    </b-modal>

  </section>
</template>

<script>
import axios from "@/axios";
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full';
import SweetalertIcon from "vue-sweetalert-icons";
import socket from "@/socket";

export default {
  name: "CreateRoom",
  components: {
    ValidationObserver,
    ValidationProvider,
    SweetalertIcon
  },
  props: {
    server: null,
  },
  data() {
    return {
      isFree: true,
      showCreateRoomModal: true,
      createdRoom: null,
      room_name: null,
      room_desc: '',
      room_price: '0.01',
      activeStep: 0,
      servers: null,
      loading: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: false,
      customNavigation: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      title: 'Create Room',
      titles: ['Create Room', 'Room Details', 'Room Created', 'Create Category'],
      selectedBillingCycle: "month",
      billingCycleOptions: ['month', 'quarter', 'semi-annual', 'annual'],
      openBillingCycle: false,
      intervalBillingCycleCount: 1
    }
  },
  computed: {
    token() {
      return this.$store.getters.get_token;
    },
    selectedServerRooms() {
      return this.$store.getters.get_selectedServerRooms;
    },

  },
  watch: {
    activeStep(step) {
      this.title = this.titles[step];
    },
    isFree(val) {
      if (val) {
        this.server_price = '0.00';
        this.selectedBillingCycle = 'Month';
      } else {
        this.server_price = '0.01';
        this.selectedBillingCycle = 'Month';
      }
    },
    room_price(newValue) {
      console.log('server_price createRoom', typeof newValue)
      this.openBillingCycle = (!(newValue === "0" || newValue === ""));
    }
  },
  methods: {
    async handleRoomSubmit() {
      this.loading = true;

      switch (this.selectedBillingCycle) {
        case "quarter":
          this.intervalBillingCycleCount = 3;
          break;

        case "semi-annual":
          this.intervalBillingCycleCount = 6;
          break;

        case "annual":
          this.intervalBillingCycleCount = 12;
          break;

        default:
          this.intervalBillingCycleCount = 1;

      }
      const postData = {
        name: this.room_name,
        description: this.room_desc,
        is_user: false,
        is_private: false,
        price: parseFloat(this.room_price),
        billingCycle: "month",
        intervalBillingCycleCount: this.intervalBillingCycleCount,
        relatedDiscounts: "string",
        image: "string",
        server: this.server._id,
        isBroadcasting: false
      }
      console.log('typeof this.room_price 2', typeof postData.price)
      try {
        const {data} = await axios.post('/rooms', postData);
        console.log('after created room', data);
        this.loading = false;
        this.createdRoom = data;
        await this.reFetch();
        //return new Error(response.data.message);
      } catch (e) {
        this.loading = false;
        console.log('after created room error');
        console.log(e.response.message);
        let message = (e.response.message) ? e.response.message : 'An Error has Occured';
        this.$refs.roomFormValidator.setErrors({'Room Name': message});
      }
    },
    async reFetch() {
      socket.disconnect();
      let user = {
        token: this.token
      };

      this.activeStep = 2;
      let result = await this.$store.dispatch('login', user);


      console.log('reFetch', this.createdRoom);


      this.$iv.socket.emit('createRoom', this.createdRoom._id);
      this.$store.commit('set_selectedRoom', this.createdRoom);
    },
    goToRoom() {
      console.log('createdRoom', this.createdRoom);
    },
    handleClose() {
      this.$emit('createRoomFormClose');
      //console.log('selectedServerRooms@@@@@@', this.selectedServerRooms)
      if (this.selectedServerRooms && this.selectedServerRooms.length === 1) {
        //window.location.reload();
      }
      // this.reFetch();
    },
    // async onRoom(selectedRoom){
    //     console.log('Im in this room ' , selectedRoom );
    //     this.$store.commit('set_selectedRoom', null);
    //     EventBus.$emit('NEW_ROOM_LOADING', true);
    //     try {
    //       let response = await axios.get('/rooms/' + selectedRoom._id );
    //       EventBus.$emit('NEW_ROOM_LOADING', false);
    //       EventBus.$emit('NEW_ROOM_LOADED', true);
    //       if (response.status >=  200 && response.status <  300 && response.data) {
    //         this.$store.commit('set_selectedRoom', response.data);
    //       }
    //     } catch (error) {
    //       EventBus.$emit('NEW_ROOM_LOADING', false);
    //       console.log(error);
    //       return error
    //     }
    // },
  }
}
</script>

<style>

#create-room {

}

#create-room.modal .modal-content {
  background-color: #1e2124 !important;
  max-width: 420px !important;
  padding: 1.5em 1em 1em;
  border-radius: 17px !important;
  overflow: hidden;
}

#create-server-form {
}

#create-server.modal .dropdown-menu {

}

#create-server.modal .dropdown-content {

}

#create-room .footer {
  margin-top: 1em;
  background-color: transparent;
  padding: 0;
  width: 100%;
  text-align: center;
}

/*h2.modal-title.medium {*/
/*  margin: 11px 0 17px 0;*/
/*  font-family: Circular, sans-serif;*/
/*  font-size: 38px;*/
/*  font-weight: 500;*/
/*  font-stretch: normal;*/
/*  font-style: normal;*/
/*  line-height: normal;*/
/*  letter-spacing: -1.16px;*/
/*  color: rgba(255, 255, 255, 0.84);*/
/*  white-space: nowrap;*/
/*}*/

/*h4.brand {*/
/*  margin: 0;*/
/*  font-family: Circular, sans-serif;*/
/*  font-size: 11px;*/
/*  font-weight: 500;*/
/*  font-stretch: normal;*/
/*  font-style: normal;*/
/*  line-height: normal;*/
/*  letter-spacing: 3.07px;*/
/*  color: #8356ff;*/
/*}*/

#create-room .upload-preview {
  /**width: 74px;
  height: 74px;
  border-radius: 26px;*/
  background-color: #632ff3;
  display: inline-block;
  margin-right: 1em;
}

#create-room .file {
  display: inline-block;
  width: auto;
}

#create-room-footer {

}


.categories-select {
  margin-top: 1em;
  width: 100%;
}

</style>
